import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { UserContext } from "../../App";
import { userAccessLevel } from "../../utilities/utitlityFunctions";

const MenuContent = () => {
  const currentUser = useContext(UserContext)?.saccoUser;
  const _location = useLocation();

  const _userAccessLevel = userAccessLevel(currentUser, _location.pathname);

  return (
    <div className="topnav" style={{ backgroundColor: "#313a46" }}>
      <div className="container-fluid">
        <nav className="navbar navbar-dark navbar-expand-lg topnav-menu">
          <div className="collapse navbar-collapse" id="topnav-menu-content">
            <ul className="navbar-nav dondaa-dash-nav">
              {_userAccessLevel.level && _userAccessLevel.level !== "basic" ? (
                <>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      to=""
                    >
                      <Icon icon="uil:dashboard" className="mr-1 mb-1" />
                      Dashboard
                    </NavLink>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      to="farechart"
                    >
                      <Icon icon="charm:apps" className="mr-1 mb-1" />
                      {/* <i className="uil-apps mr-1"></i> */}
                      FareChart
                    </NavLink>
                  </li>
                  <li
                    className="nav-item dropdown"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink className="nav-link arrow-none" to="transactions">
                      <Icon icon="charm:apps" className="mr-1 mb-1" />
                      Transactions
                    </NavLink>
                  </li>
                  <li
                    className="nav-item dropdown"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink className="nav-link arrow-none" to="crew-manager">
                      <Icon icon="charm:apps" className="mr-1 mb-1" />
                      Crew Manager
                    </NavLink>
                  </li>
                  {/* <li
                    className="nav-item dropdown"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink className="nav-link arrow-none" to="crew-manager">
                      <Icon icon="charm:apps" className="mr-1 mb-1" />
                      Crew Manager
                    </NavLink>
                       <div class="dropdown-menu show" aria-labelledby="topnav-dashboards" data-bs-popper="static">
                                        <a href="dashboard-analytics.html" class="dropdown-item">Analytics</a>
                                        <a href="index.html" class="dropdown-item">Ecommerce</a>
                                        <a href="dashboard-projects.html" class="dropdown-item">Projects</a>
                                        <a href="dashboard-crm.html" class="dropdown-item">CRM</a>
                                        <a href="dashboard-wallet.html" class="dropdown-item">E-Wallet</a>
                                    </div>
                  </li> */}
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      to="matatu"
                    >
                      <Icon
                        icon="fluent:vehicle-bus-20-filled"
                        className="mr-1 mb-1"
                      />
                      Matatu
                    </NavLink>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      to="parcel-service"
                    >
                      <Icon
                        icon="fluent:vehicle-bus-20-filled"
                        className="mr-1 mb-1"
                      />
                      Parcel Service
                    </NavLink>
                  </li>
                  {_userAccessLevel.level === "withdrawal" ||
                  _userAccessLevel.level === "admin" ? (
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target="#topnav-menu-content"
                    >
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="withdraw"
                      >
                        <Icon icon="uil:money-insert" className="mr-1 mb-1" />
                        Withdraw
                      </NavLink>
                    </li>
                  ) : null}
                  {_userAccessLevel.level === "withdrawal" ||
                  _userAccessLevel.level === "admin" ? (
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target="#topnav-menu-content"
                    >
                      <NavLink
                        className="nav-link dropdown-toggle arrow-none"
                        to="savings"
                      >
                        <Icon icon="uil:money-insert" className="mr-1 mb-1" />
                        Savings
                      </NavLink>
                    </li>
                  ) : null}

                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                  >
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      to="notifications"
                    >
                      <Icon icon="akar-icons:bell" className="mr-1 mb-1" />
                      {/* <i className="uil-apps mr-1"></i> */}
                      Notifications
                    </NavLink>
                  </li>
                </>
              ) : null}
              <li
                className="nav-item dropdown"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <NavLink className="nav-link arrow-none" to="operators">
                  <Icon icon="octicon:people-24" className="mr-1 mb-1" />
                  Operators
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MenuContent;
