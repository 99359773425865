import React from "react";

import Notification from "./Notification";
import dondaaLogo from "../../assets/images/dondaa.png";
import MenuContent from "./MenuContent";
import { fetchNotifications } from "../../services/apiCalls";
import { fetchErrorUI } from "../../utilities/utitlityFunctions";

const HorizontalNav = () => {
  const [loading, setLoading] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);

  // const updateNotifications = (page, size) => {
  //   setLoading(true);
  //   fetchNotifications(page, size)
  //     .then((_res) => {
  //       const _data = _res;
  //       setLoading(false);
  //       if (_data.content?.length) setNotifications(_data.content);
  //     })
  //     .catch((_err) => {
  //       setLoading(false);
  //       fetchErrorUI(_err);
  //     });
  // };
  React.useEffect(() => {
    //updateNotifications();
  }, []);

  return (
    <>
      <div className="navbar-custom topnav-navbar">
        <div className="container-fluid">
          <a href="/" className="topnav-logo">
            <span className="topnav-logo-lg">
              <img
                src={dondaaLogo}
                alt=""
                style={{ height: "3.5rem", width: "auto" }}
              />
            </span>
            <span className="topnav-logo-sm">
              <img
                src={dondaaLogo}
                alt=""
                style={{ height: "3.5rem", width: "auto" }}
              />
            </span>
          </a>

          <Notification
            notifications={notifications}
            setNotifications={setNotifications}
            loading={loading}
            setLoading={setLoading}
          />

          <a
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#topnav-menu-content"
          >
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          {/* <div className="app-search dropdown">
      <div
        className="dropdown-menu dropdown-menu-animated dropdown-lg"
        id="search-dropdown"
      >
        <!-- item-->
        <div className="dropdown-header noti-title">
          <h5 className="text-overflow mb-2">
            Found <span className="text-danger">17</span> results
          </h5>
        </div>

        <!-- item-->
        <a href="" className="dropdown-item notify-item">
          <i className="uil-notes font-16 mr-1"></i>
          <span>Analytics Report</span>
        </a>

        <!-- item-->
        <a href="" className="dropdown-item notify-item">
          <i className="uil-life-ring font-16 mr-1"></i>
          <span>How can I help you?</span>
        </a>

        <!-- item-->
        <a href="" className="dropdown-item notify-item">
          <i className="uil-cog font-16 mr-1"></i>
          <span>User profile settings</span>
        </a>

        <!-- item-->
        <div className="dropdown-header noti-title">
          <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
        </div>

        <div className="notification-list">
          <!-- item-->
          <a href="" className="dropdown-item notify-item">
            <div className="media">
              <img
                className="d-flex mr-2 rounded-circle"
                src="assets/images/users/avatar-2.jpg"
                alt="Generic placeholder image"
                height="32"
              />
              <div className="media-body">
                <h5 className="m-0 font-14">Erwin Brown</h5>
                <span className="font-12 mb-0">UI Designer</span>
              </div>
            </div>
          </a>

          <!-- item-->
          <a href="" className="dropdown-item notify-item">
            <div className="media">
              <img
                className="d-flex mr-2 rounded-circle"
                src="assets/images/users/avatar-5.jpg"
                alt="Generic placeholder image"
                height="32"
              />
              <div className="media-body">
                <h5 className="m-0 font-14">Jacob Deo</h5>
                <span className="font-12 mb-0">Developer</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div> */}
        </div>
      </div>
      <MenuContent />
    </>
  );
};

export default HorizontalNav;
