import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">{new Date().getFullYear()} © Dondaa</div>
          <div className="col-md-6">
            <div className="text-md-right footer-links d-none d-md-block">
              <NavLink to="/">About</NavLink>
              <NavLink to="/">Support</NavLink>
              <NavLink to="/">Contact Us</NavLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
