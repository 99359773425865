import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { toast } from "react-toastify";

import { registerToSacco } from "./apiCalls";
import { app } from "./firebaseInit";

const auth = getAuth(app);
const actionCodeSettings = {
  url: window.location.origin + "/",
  handleCodeInApp: true,
};

// helper functions
function handleAuthError(code, message = "") {
  switch (code) {
    case "auth/wrong-password":
      return toast.error("Incorect Password");
    //   return (errorMessage.innerText = "Incorect Password");
    case "auth/user-not-found":
      return toast.error("User with provided email does not exist");
    //   return (errorMessage.innerText =
    //     "User with provided email does not exist");
    case "WEAK_PASSWORD : Password should be at least 6 characters":
      return toast.error("Password should be at least 6 characters");
    default:
      //   return (errorMessage.innerText = "Error occured! Please try again");
      return toast.error(message);
  }
}

export function saccoRegistration(saccoUser) {
  const _userName = saccoUser.displayName
    ? saccoUser.displayName.split(" ")
    : [];

  // set payload values
  const currentUser = {
    email: saccoUser.email,
    firstName: saccoUser.displayName ? _userName[0] : "",
    id: "",
    idNumber: "",
    isVerified: false,
    lastName: saccoUser.displayName ? _userName[_userName.length - 1] : "",
    matatuList: [],
    photo: saccoUser.photoURL || "",
    role: "",
    sacco: "",
  };

  // register user to sacco
  registerToSacco(currentUser)
    .then((_res) => {
      if (_res?.payload?.status === "success") {
        toast.success(_res.payload.message);
        window.location.href = "/profile";
      }
    })
    .catch((_err) => {
      // TODO: register to sacco
      toast.error("Sorry an error occured creating profile.");
      window.location.href = "/sacco-registration";
    });
}
// *****

export const emailSignIn = (email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      handleAuthError(error.code);
    });
};

// google sign in
const googleProvider = new GoogleAuthProvider();

export function signInWithGoogle(register = false) {
  signInWithPopup(auth, googleProvider)
    .then(function (result) {
      // var token = result.credential.accessToken;
      var user = result.user;

      // if registration call register to sacco
      if (register) saccoRegistration(user);

      //   window.location.href = "profile.html";
    })
    .catch(function (error) {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // var email = error.email;
      // var credential = error.credential;
      console.log(error);
    });
}

// google signup
export const signUpWithGoogle = () => {
  signInWithGoogle(true);
};

// Password and email signup
export function emailSignUp(email, password) {
  createUserWithEmailAndPassword(auth, email, password)
    .then(function (userCredentials) {
      if (userCredentials) {
        const user = userCredentials.user;
        // return;
        // register to sacco func
        saccoRegistration(user);

        user
          .sendEmailVerification(actionCodeSettings)
          .then(function () {
            document
              .getElementById("signup-complete")
              .classList.remove("d-none");
            document.getElementById("signup-component").classList.add("d-none");
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    })
    .catch(function (err) {
      handleAuthError(err.code, err.message);
    });
}

// logout
export const logout = () => {
  signOut(auth)
    .then((_e) => {
      window.location.href = "/login";
    })
    .catch((_err) => {
      toast.error("Failed to log out, please try again.");
    });
};
