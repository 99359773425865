import React from "react";

const NotApprovedBanner = ({ message = "" }) => {
  return (
    <div className="container" style={{ maxWidth: "98vw" }}>
      <div className="container rounded bg-primary text-white fs-2 text text-center mt-2">
        <p className="p-2 m-0">
          {message
            ? message
            : "Pending Verification, You will be able to access this page once verified."}
        </p>
      </div>
    </div>
  );
};

export default NotApprovedBanner;
