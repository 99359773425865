import {
  getFirestore,
  collection,
  getDocs,
  doc,
  query,
  where,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";

import { firebaseConfig } from "../utilities/constanst";

//   initialize firebase app
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

// initialize database
const db = getFirestore(firebaseApp);
export const authRoutes = ["/register", "/login"];

// fetchUser
const fetchUser = async (userEmail) => {
  try {
    const _resultsArray = [];
    if (userEmail) {
      const q = query(
        collection(db, "SaccoMembers"),
        where("email", "==", userEmail)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        _resultsArray.push({ id: doc.id, ...doc.data() });
      });

      const _user = _resultsArray[0] || {};
      // accessController(_user.verified);
      return _user;
    }
  } catch (error) {
    console.log("an error occured while fetching user: ", error);
  }
};

// check auth

export function AppInitialization(setUser) {
  let currentUser, saccoUser;
  // TODO: add loading spinner
  try {
    // eslint-disable-next-line no-undef
    return onAuthStateChanged(auth, async function (user) {
      if (user) {
        saccoUser = await fetchUser(user.email);
        // window.sessionStorage.setItem("authState", true);

        currentUser = {
          displayName: user.displayName,
          name: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          isAnonymous: user.isAnonymous,
          uid: user.uid,
          provideData: user.provideData,
        };

        // window.sessionStorage.setItem("profile", JSON.stringify(saccoUser));
        // eslint-disable-next-line no-undef
        // setNavProfileDetails(currentUser);
        // if (window.location.pathname === "/profile") {
        //   // eslint-disable-next-line no-undef

        // //   window.sessionStorage.setItem("profile", JSON.stringify(saccoUser));
        //   //   setProfileDetails(currentUser, saccoUser);
        //   return;
        // }
        window.localStorage.setItem("authState", true);
        setUser({ currentUser, saccoUser });
        if (authRoutes.includes(window.location.pathname)) {
          window.location.href = "/profile";
        }

        return;
      }

      window.localStorage.setItem("authState", false);
      setUser({ currentUser, saccoUser });
    });
  } catch (error) {
    console.log("an error occured while updating auth");
  }
  return { currentUser, saccoUser };
}
