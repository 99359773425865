import { useEffect, useState, createContext } from "react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

// style imports
import "./App.css";
import "./assets/scss/app-modern.scss";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap";

// local imports
import HorizontalNav from "./components/Horizontal-Nav";
import Footer from "./components/Footer";
import Routing from "./routing";
import { dashboardLogin } from "./services/apiCalls";
import { AppInitialization } from "./services/firebase";
import { getAuthState } from "./utilities/utitlityFunctions";
import { useInterval } from "./utilities/customHooks";

export const UserContext = createContext();
export const AuthContext = createContext();

function App() {
  const [auth, setAuth] = useState();
  const [user, setUser] = useState();
  const _location = useLocation();
  const NoNavPages = ["/login", "/register", "/sacco-registration"];
  const _yesNav = !NoNavPages.includes(_location.pathname);

  // helper functions
  const dashLogin = () => {
    dashboardLogin()
      .then((_res) => {
        window.sessionStorage.setItem("betkn", _res.token);
      })
      .catch((_err) => {
        console.log("err login: ", _err);
        // try again
        // dashLogin();
      });
  };

  // refresh token every half-hour
  useInterval(dashLogin, 130000);

  useEffect(() => {
    if (user && user?.currentUser) {
      setAuth(true);
      return;
    }
    setAuth(false);
    if (!getAuthState() && _yesNav) {
      window.location.href = "/login";
    }
  }, [user]);

  useEffect(() => {
    // make dashboard login call
    dashLogin();

    const unsubscribe = AppInitialization(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      <UserContext.Provider value={user}>
        {_yesNav ? <HorizontalNav /> : null}
        <div className={_yesNav ? "wrapper" : ""}>
          <div className={_yesNav ? "content-page" : ""}>
            <div className={_yesNav ? "content" : ""}>
              <Routing auth={auth} />
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer theme="colored" position="top-center" />
      </UserContext.Provider>
    </div>
  );
}

export default App;
