import { Icon } from "@iconify/react";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import moment from "moment/moment";

import { UserContext } from "../../App";
import { logout } from "../../services/auth";
import InlineSpinner from "../widgets/InlineSpinner";

const Notification = ({ notifications, loading }) => {
  const user = React.useContext(UserContext);

  const _timeDifference = (_date) => {
    let _string = "";
    const pastDate = moment(_date);
    const currentDate = moment();

    const timeDifference = currentDate.diff(pastDate);
    const duration = moment.duration(timeDifference);
    const daysAgo = Math.floor(duration.asDays());
    const hoursAgo = Math.floor(duration.asHours() % 24);
    const minutesAgo = Math.floor(duration.asMinutes() % 60);

    if (daysAgo) _string = `${daysAgo} days ago `;
    else if (hoursAgo) _string = `${hoursAgo} h ago `;
    else _string = `${minutesAgo} min ago`;

    return _string;
  };

  return (
    <ul className="list-unstyled topbar-right-menu float-right mb-0">
      <li className="dropdown notification-list">
        <Link
          className="nav-link dropdown-toggle arrow-none"
          data-toggle="dropdown"
          href="#"
          id="topbar-notifydrop"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="dripicons-bell noti-icon">
            <Icon icon="akar-icons:bell" />
          </i>
          <span className="noti-icon-badge"></span>
        </Link>
        <div
          className="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg"
          aria-labelledby="topbar-notifydrop"
        >
          <div className="dropdown-item noti-title">
            <h5 className="m-0">
              <span className="float-right disable">
                <Link href="" className="text-dark" aria-disabled disabled>
                  <small>Clear All</small>
                </Link>{" "}
              </span>
              Notification
            </h5>
          </div>

          <div
            style={{ maxHeight: 230 }}
            data-simplebar
            id="notifications-container"
          >
            {/* {!loading ? (
              <>
                {notifications.slice(0, 5).map((_notification, _index) => (
                  <Link
                    key={`${_notification.id}`}
                    href=""
                    className="dropdown-item notify-item"
                  >
                    <div className="notify-icon bg-primary">
                      <i className="mdi mdi-comment-account-outline"></i>
                    </div>
                    <p className="notify-details">
                      <small>{`${_notification.message.slice(
                        0,
                        40
                      )} ...`}</small>
                      <small className="text-muted">
                        {_timeDifference(_notification.created_at)}
                      </small>
                    </p>
                  </Link>
                ))}
              </>
            ) : (
              <div>
                <InlineSpinner className="text-center" />
              </div>
            )} */}
          </div>

          {/* <Link
            href=""
            className="dropdown-item text-center text-primary notify-item notify-all"
          ></Link> */}
          <NavLink
            className="dropdown-item text-center text-primary notify-item notify-all"
            to="notifications"
          >
            View All
          </NavLink>
        </div>
      </li>

      <li className="notification-list">
        <Link className="nav-link right-bar-toggle" href="">
          <i className="dripicons-gear noti-icon">
            <Icon icon="akar-icons:gear" className="" />
          </i>
        </Link>
      </li>

      <li className="dropdown notification-list">
        <Link
          className="nav-link dropdown-toggle nav-user arrow-none mr-0"
          data-toggle="dropdown"
          id="topbar-userdrop"
          href="#"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="account-user-avatar">
            <img
              id="nav-avatar"
              src={user?.currentUser?.photoURL || user?.saccoUser?.photo}
              alt="avatar"
              className="rounded-circle"
            />
          </span>
          <span>
            <span className="account-user-name" id="nav-name">
              {user?.currentUser?.displayName ||
                user?.saccoUser?.firstName + " " + user?.saccoUser?.lastName ||
                ""}
            </span>
            <span className="account-position" id="nav-role"></span>
          </span>
        </Link>
        <div
          className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
          aria-labelledby="topbar-userdrop"
        >
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">Welcome !</h6>
          </div>

          <Link to="profile" className="dropdown-item notify-item">
            <i className="mdi mdi-account-circle mr-1"></i>
            <span>My Account</span>
          </Link>

          <Link href="" className="dropdown-item notify-item">
            <i className="mdi mdi-account-edit mr-1"></i>
            <span>Settings</span>
          </Link>

          <Link href="" className="dropdown-item notify-item">
            <i className="mdi mdi-lifebuoy mr-1"></i>
            <span>Support</span>
          </Link>

          <Link href="" className="dropdown-item notify-item">
            <i className="mdi mdi-lock-outline mr-1"></i>
            <span>Lock Screen</span>
          </Link>

          <button
            id="logout"
            onClick={logout}
            className="dropdown-item notify-item"
          >
            <i className="mdi mdi-logout mr-1"></i>
            <span>Logout</span>
          </button>
        </div>
      </li>
    </ul>
  );
};

export default Notification;
