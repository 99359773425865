import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD0erYBKr8e0fB2Y626a5Q_biCFAa9eyOM",
  authDomain: "abiria-op.firebaseapp.com",
  databaseURL: "https://abiria-op.firebaseio.com",
  projectId: "abiria-op",
  storageBucket: "abiria-op.appspot.com",
  messagingSenderId: "179639161380",
  appId: "1:179639161380:web:f3209af5c654dfdd46beb1",
};

export const app = initializeApp(firebaseConfig);
