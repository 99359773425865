import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { UserContext } from "./App";

import NotApprovedBanner from "./components/NotApprovedBanner";
import PageLoadingSpinner from "./components/widgets/PageLoadingSpinner";
import { userAccessLevel } from "./utilities/utitlityFunctions";

// page imports
const Dashboard = lazy(() => import("./pages/Dashboard"));
const CrewManager = lazy(() => import("./pages/Crew-Manager"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const MatatuManager = lazy(() => import("./pages/Matatu-Manager"));
const FareChart = lazy(() => import("./pages/Farechart"));
const Trips = lazy(() => import("./pages/Trips"));
const Withdraw = lazy(() => import("./pages/Withdraw"));
const Profile = lazy(() => import("./pages/Profile"));
const SaccoRegistration = lazy(() => import("./pages/Sacco-Registration"));
const Operators = lazy(() => import("./pages/Operators"));
const SavingsPage = lazy(() => import("./pages/Savings"));
const ParcelPage = lazy(() => import("./pages/Parcel"));
const Notifications = lazy(() => import("./pages/Notifications"));
const ChatMessages = lazy(() => import("./pages/Notifications/ChatComponent"));

const RouteGuard = ({ auth, children }) => {
  const [approved, setApproved] = useState("loading");
  const _location = useLocation();
  const user = useContext(UserContext);

  const _userAccessLevel = userAccessLevel(user?.saccoUser, _location.pathname);

  useEffect(() => {
    if (user?.saccoUser?.verified) {
      return setApproved(true);
    }
    setApproved(false);
  }, [user]);

  return auth &&
    (approved !== "loading" || _location.pathname === "/profile") ? (
    <>
      {user?.saccoUser.role ? (
        <>
          {approved && _userAccessLevel.allowedPage ? (
            children
          ) : (
            <NotApprovedBanner
              message={
                approved ? "Sorry you are not allowed to access this page." : ""
              }
            />
          )}
        </>
      ) : (
        <NotApprovedBanner />
      )}
    </>
  ) : (
    <PageLoadingSpinner />
  );
};

const Routing = ({ auth }) => {
  return (
    <Routes>
      <Route
        exact
        path="withdraw"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <Withdraw />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="notifications/:recipient"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <ChatMessages />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="notifications"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <Notifications />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="Savings"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <SavingsPage />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="crew-manager"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <CrewManager />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="matatu"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <MatatuManager />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="parcel-service"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <ParcelPage />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="transactions"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <Trips />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="farechart"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <FareChart />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="login"
        element={
          <Suspense fallback={<>..</>}>
            <Login />
          </Suspense>
        }
      />
      <Route
        exact
        path="register"
        element={
          <Suspense fallback={<>..</>}>
            <Register />
          </Suspense>
        }
      />
      <Route
        exact
        path="sacco-registration"
        element={
          <Suspense fallback={<>..</>}>
            <SaccoRegistration />
          </Suspense>
        }
      />
      <Route
        exact
        path="profile"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <Profile />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="operators"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <Operators />
            </RouteGuard>
          </Suspense>
        }
      />
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<>..</>}>
            <RouteGuard auth={auth}>
              <Dashboard />
            </RouteGuard>
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Routing;
